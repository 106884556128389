@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
:root {
  --background: #f9f9f9;
  --padding-left: 140px;
  --bleu: #1b89ce;
  --gris_casse: #ececec;
  --blanc: #fff;
  --sombre: #131313;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  background: var(--background);
  overflow-x: hidden;
}

nav {
  padding: 20px calc(var(--padding-left) / 2);
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: flex;
}

.logo_wrap {
  height: 75px;
  min-width: 75px;
  background: var(--gris_casse);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.lux {
  filter: drop-shadow(0 0 2px #0006);
  height: 60px;
  width: 60px;
  border-radius: 2px;
}

form {
  width: 100%;
  background: none;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.search {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 1px 2px #00000040;
}

.search__region {
  width: 10%;
  background: #f0f0f0;
  border-radius: 10px 0 0 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 24px;
  display: flex;
}

select {
  color: var(--bleu);
  font-size: 14px;
  font: inherit;
  background: none;
  border: none;
  outline: none;
}

.search__name {
  background: var(--blanc);
  width: 100%;
  border-radius: 0 10px 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.search__name input {
  width: 100%;
  height: 100%;
  color: var(--sombre);
  border: none;
  border-radius: 0 10px 10px 0;
  padding: 10px;
  font-size: 24px;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #aaa;
}

.search_icon {
  visibility: hidden;
  margin-right: 20px;
}

.search__name .search_icon {
  font-size: 36px;
}

.search_icon--active {
  visibility: visible;
}

.search_region--active {
  background: var(--bleu);
  color: var(--blanc);
}

.confirmation {
  height: 50px;
  width: fit-content;
  background: var(--bleu);
  z-index: 1;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  display: flex;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.confirm__message p {
  color: var(--blanc);
  font-size: 14px;
}

.sep {
  width: 1px;
  height: 50%;
  background: #68a6cd;
}

.undo-btn {
  color: var(--blanc);
  border-radius: 50%;
  padding: 8px;
  transition: all .2s;
}

.undo-btn:hover {
  background: #68a6cd;
}

.material-symbols-rounded.undo-btn {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.tooltip {
  margin-top: 20px;
  margin-left: var(--padding-left);
  height: 84px;
  display: inline-block;
  position: relative;
}

.tooltip img {
  height: 84px;
}

.tooltip__text {
  font-size: 16px;
}

.tooltip .tooltip__text {
  background-color: var(--gris_casse);
  width: 60vw;
  z-index: 1;
  color: var(--sombre);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  padding: 20px;
  line-height: 1.6rem;
  transition: all .2s;
  position: absolute;
  top: 0%;
  left: 120%;
  transform: translateY(10%);
}

.tooltip .tooltip__text:after {
  content: "";
  border-style: solid;
  border-width: 15px;
  border-color: transparent var(--gris_casse) transparent transparent;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
}

.tooltip:hover .tooltip__text {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

section {
  padding: 0 var(--padding-left);
  height: fit-content;
  width: 60%;
  border-radius: 5px;
}

.fav_summoner {
  margin-top: 80px;
}

.section__title {
  background: var(--gris_casse);
  height: 60px;
  border-radius: 5px 5px 0 0;
  align-items: center;
  display: flex;
  position: relative;
}

.section__title:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  display: block;
  position: absolute;
  bottom: 0;
}

.section__title_p {
  color: var(--bleu);
  padding-left: 25px;
  font-size: 26px;
  font-weight: 500;
}

.section__items_wrap {
  height: 60px;
  background: var(--blanc);
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  position: relative;
}

.section__items_names {
  color: var(--sombre);
  font-size: 24px;
}

.material-symbols-rounded.close {
  font-size: 36px;
}

.no_fav {
  font-size: 20px;
}

.lastFav {
  border-radius: 0 0 5px 5px;
}

.section__items_wrap:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.lastFav:after {
  content: "";
  width: 0%;
  height: 0;
}

.last_name {
  border-radius: 0 0 5px 5px;
}

.last_name:after {
  content: "";
  display: none;
}

.close {
  border-radius: 50%;
  padding: 3px;
  transition: all .1s;
}

.close:hover {
  cursor: pointer;
  color: var(--blanc);
  background: #1b89ce;
}

.no_data {
  width: 100%;
  height: fit-content;
  padding: 25px var(--padding-left);
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  display: flex;
}

.no_data_img {
  pointer-events: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.no_data_img img {
  height: 250px;
}

.no_data_p {
  text-align: center;
  color: #555;
  user-select: none;
  font-size: 28px;
  font-weight: 500;
}

.no_data_back {
  width: 174px;
  cursor: pointer;
  background: none;
  border: 1px solid #bbb;
  border-radius: 20px;
  outline: none;
  justify-content: flex-end;
  align-items: center;
  margin-top: 100px;
  padding: 5px 15px;
  font-size: 18px;
  transition: all .4s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.no_data_back .back_arrow {
  opacity: 0;
  color: var(--bleu);
  cursor: pointer;
  transition: all .4s;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
}

.no_data_back:hover {
  border: 1px solid var(--bleu);
  width: 200px;
  background: #f9f9f9;
}

.no_data_back:active {
  background: #d6d6d6;
  box-shadow: 0 0 0 4px #1b89ce33;
}

.no_data_back:hover .back_arrow, .no_data_back:active .back_arrow {
  opacity: 1;
  transform: translate(50%, -50%);
}

.summoner {
  width: 70vw;
  justify-content: space-between;
  margin-top: 25px;
  display: flex;
}

.summoner_hero {
  flex-direction: column;
  display: flex;
}

.summoner_hero__p {
  color: #aaa;
  font-size: 18px;
}

.summoner_hero__name {
  font-size: 48px;
}

.summoner_icon {
  width: 100%;
  padding-left: var(--padding-left);
  justify-content: space-between;
  display: flex;
}

.summoner_icon__img {
  background: var(--bleu);
  width: 110px;
  height: 110px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.summoner_icon__level {
  border: 3px solid var(--bleu);
  background: #eee;
  border-radius: 50px;
  padding: 0 10px;
  position: absolute;
  bottom: 0;
  transform: translateY(40%);
}

.summoner_icon__level_p {
  color: var(--bleu);
  user-select: none;
  font-size: 18px;
}

.summoner_icon__img, .summoner_icon__img img {
  border-radius: 5px;
}

.summoner_icon__img img {
  height: 100px;
  width: 100px;
}

.summoner_icon_handler {
  height: fit-content;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.summoner_icon_handler .star {
  font-size: 48px;
}

.material-symbols-rounded {
  cursor: default;
  user-select: none;
}

@keyframes star-active {
  0% {
    color: var(--bleu);
    transform: scale(1);
  }

  25% {
    color: #65c4ff;
    transform: scale(1.5);
  }

  100% {
    color: var(--bleu);
    transform: scale(1);
  }
}

.star-active {
  color: var(--bleu);
  animation: star-active .5s;
}

.mastery {
  margin-top: 10px;
  display: none;
}

.mastery--active {
  display: block;
}

.search__mastery {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  display: flex;
  box-shadow: 0 1px 2px #00000040;
}

.search__mastery input {
  width: 100%;
  height: 100%;
  color: var(--bleu);
  border: none;
  border-radius: 5px;
  padding: 15px;
  font-size: 24px;
}

.search__mastery input::placeholder {
  color: #777;
}

.listaus {
  background: var(--blanc);
  border-radius: 5px;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
  padding: 15px;
  list-style: none;
  display: none;
  box-shadow: 0 1px 2px #00000040;
}

.listaus__item {
  display: none;
}

.listaus__item_wrap {
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  display: flex;
}

.listaus__item__img {
  justify-content: center;
  align-items: center;
  display: flex;
}

.listaus__item__img img {
  border-radius: 5px;
}

.listaus__item__name {
  font-size: 20px;
}

.champ_info__card {
  background: none;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  margin-top: 20px;
  font-size: 24px;
  display: flex;
}

.champ_info__card_img {
  justify-content: center;
  align-items: center;
  display: flex;
}

.champ_info__card_img img, .champ_info__card_rank img {
  height: 55px;
  width: 55px;
}

.champ_info__card__item {
  background: var(--blanc);
  width: 100%;
  height: 80px;
  border-radius: 5px;
  gap: 15px;
  padding: 15px;
  display: flex;
  box-shadow: 0 1px 2px #00000040;
}

.champ_info__card__item__part1, .champ_info__card__item__part2, .champ_info__card__item__part3 {
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  display: flex;
  position: relative;
}

.champ_info__card__item__part1 {
  gap: 15px;
}

.champ_info__card__item__part3 {
  width: 100%;
}

.separator {
  height: 100%;
  width: 2px;
  border: 1px solid #d9d9d9;
}

@media (max-width: 1024px) {
  :root {
    --background: #f9f9f9;
    --padding-left: 100px;
  }

  nav {
    padding: calc(var(--padding-left) / 2);
    gap: 30px;
  }

  .logo_wrap {
    height: 80px;
    min-width: 80px;
  }

  .lux {
    height: 70px;
    width: 70px;
  }

  .search {
    height: 60px;
  }

  .search__region {
    width: 20%;
    font-size: 28px;
  }

  .search__name input {
    font-size: 28px;
  }

  input::placeholder {
    color: #777;
  }

  .search_icon {
    margin-right: 20px;
  }

  .search__name .search_icon {
    font-size: 36px;
  }

  section {
    height: fit-content;
    width: 100%;
  }

  .summoner {
    width: 100vw;
    margin-top: 0;
  }

  .champ_info__card__item__part1, .champ_info__card__item__part2, .champ_info__card__item__part3 {
    font-size: 36px;
  }

  .champ_info__card__item__part1 {
    gap: 25px;
  }

  .champ_info__card__item {
    width: 100%;
    height: 80px;
    gap: 25px;
  }
}

@media (max-width: 414px), (max-width: 820px) {
  :root {
    --background: #f9f9f9;
    --padding-left: 40px;
  }

  nav {
    gap: 20px;
    padding: 20px;
  }

  .logo_wrap {
    height: 60px;
    min-width: 60px;
  }

  .lux {
    height: 50px;
    width: 50px;
  }

  .search {
    height: 40px;
  }

  .search__region {
    width: 30%;
    font-size: 16px;
  }

  .search__name input {
    font-size: 16px;
  }

  input::placeholder {
    color: #777;
  }

  .search_icon {
    margin-right: 15px;
  }

  .search__name .search_icon {
    font-size: 24px;
  }

  .tooltip, .tooltip img {
    height: 43px;
  }

  .tooltip__text {
    font-size: 9px;
  }

  .tooltip .tooltip__text {
    width: 300px;
    padding: 10px;
    line-height: .9rem;
    top: -35%;
    left: 100%;
    transform: translateY(5px);
  }

  .tooltip .tooltip__text:after {
    border-width: 5px;
    margin-top: -5px;
    top: 50%;
    right: 100%;
  }

  section {
    height: fit-content;
    width: 100%;
  }

  .fav_summoner {
    margin-top: 40px;
  }

  .section__title {
    height: 35px;
  }

  .section__title_p {
    padding-left: 10px;
    font-size: 16px;
  }

  .section__items_wrap {
    height: 40px;
    padding-left: 10px;
    padding-right: 20px;
  }

  .no_fav {
    font-size: 14px;
  }

  .section__items_names {
    font-size: 16px;
  }

  .material-symbols-rounded.close {
    font-size: 24px;
  }

  .no_data {
    width: 100%;
    height: fit-content;
    padding: 15px var(--padding-left);
    gap: 5px;
  }

  .no_data_img img {
    height: 150px;
  }

  .no_data_p {
    color: var(--sombre);
    font-size: 16px;
    font-weight: 500;
  }

  .no_data_back {
    width: 140px;
    border: 1px solid #bbb;
    border-radius: 20px;
    margin-top: 50px;
    padding: 5px 15px;
    font-size: 13.333px;
  }

  .no_data_back:hover {
    width: 165px;
  }

  .summoner {
    width: 100vw;
    margin-top: 0;
  }

  .summoner_hero__p {
    font-size: 12px;
  }

  .summoner_hero__name {
    font-size: 26px;
  }

  .summoner_icon__img {
    width: 56px;
    height: 56px;
  }

  .summoner_icon__img img {
    height: 50px;
    width: 50px;
  }

  .summoner_icon__level {
    border: 2px solid var(--bleu);
    padding: 0 5px;
    transform: translateY(40%);
  }

  .summoner_icon__level_p {
    font-size: 10px;
  }

  .summoner_icon_handler .star {
    font-size: 36px;
  }

  .search__mastery {
    width: 100%;
    height: 100%;
    margin-top: 5px;
    box-shadow: 0 1px 2px #00000040;
  }

  .search__mastery input {
    font-size: 16px;
  }

  .listaus {
    padding: 15px;
  }

  .champ_info__card {
    gap: 15px;
    font-size: 20px;
  }

  .champ_info__card_img img, .champ_info__card_rank img {
    height: 40px;
    width: 40px;
  }

  .champ_info__card__item {
    width: 100%;
    height: 60px;
    gap: 15px;
    padding: 10px;
  }

  .champ_info__card__item__part1, .champ_info__card__item__part2, .champ_info__card__item__part3 {
    font-size: 16px;
  }
}

/*# sourceMappingURL=index.01a0d5a0.css.map */
