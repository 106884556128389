@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --background: #f9f9f9;
    --padding-left: 140px;
    --bleu: #1B89CE;
    --gris_casse: #ececec;
    --blanc: #ffffff;
    --sombre: #131313;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

html,
body {
    height: 100%;
    width: 100%;
}

body {
    overflow-x: hidden;
    background: var(--background);
}

/* NAV -------------------------- */
nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 20px calc(var(--padding-left) / 2);
}

.logo_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    min-width: 75px;
    background: var(--gris_casse);
    border-radius: 2px;
}

.lux {
    border-radius: 2px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
    height: 60px;
    width: 60px;
}

form {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 2px;
    width: 100%;
}

.search {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.search__region {
    background: #f0f0f0;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0 0 10px;
    gap: 5px;
    font-size: 24px;
}

select {
    border: none;
    font-size: 14px;
    color: var(--bleu);
    outline: none;
    font: inherit;
    background: transparent;
}

.search__name {
    background: var(--blanc);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 10px 10px 0;
}

.search__name input {
    border: none;
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 24px;
    color: var(--sombre);
    border-radius: 0 10px 10px 0;
}

input:focus {
    outline: none;
}

input::placeholder {
    color: #aaaaaa;
}

.search_icon {
    margin-right: 20px;
    visibility: hidden;
}

.search__name .search_icon {
    font-size: 36px;
}

.search_icon--active {
    visibility: visible;
}

.search_region--active {
    background: var(--bleu);
    color: var(--blanc);
}

/* CONFIRMATION -------------------------- */
.confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: fit-content;
    background: var(--bleu);
    border-radius: 5px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    gap: 10px;
    padding: 5px 10px;
}

.confirm__message p {
    font-size: 14px;
    color: var(--blanc);
}

.sep {
    width: 1px;
    height: 50%;
    background: #68a6cd;
}

.undo-btn {
    color: var(--blanc);
    border-radius: 50%;
    padding: 8px;
    transition: all .2s ease;
}

.undo-btn:hover {
    background: #68a6cd;
}

.material-symbols-rounded.undo-btn {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

/* TOOLTIP -------------------------- */
.tooltip {
    margin-top: 20px;
    margin-left: var(--padding-left);
    height: 84px;
    position: relative;
    display: inline-block;
}

.tooltip img {
    height: 84px;
}

.tooltip__text {
    font-size: 16px;
}

.tooltip .tooltip__text {
    background-color: var(--gris_casse);
    padding: 20px;
    width: 60vw;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    top: 0%;
    left: 120%;

    color: var(--sombre);
    line-height: 1.6rem;
    pointer-events: none;

    visibility: hidden;
    opacity: 0;
    transform: translateY(10%);
    transition: all 0.2s ease;
}

.tooltip .tooltip__text::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 15px;
    border-style: solid;
    border-color: transparent var(--gris_casse) transparent transparent;
}

.tooltip:hover .tooltip__text {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

/* FAVORITE SUMMONERS -------------------------- */
section {
    padding: 0 var(--padding-left);
    border-radius: 5px;
    height: fit-content;
    width: 60%;
}

.fav_summoner {
    margin-top: 80px;
}

.section__title {
    background: var(--gris_casse);
    border-radius: 5px 5px 0 0;
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
}

.section__title::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #d9d9d9;
}

.section__title_p {
    font-size: 26px;
    color: var(--bleu);
    font-weight: 500;
    padding-left: 25px;
}

.section__items_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    height: 60px;
    background: var(--blanc);
    position: relative;
}

.section__items_names {
    font-size: 24px;
    color: var(--sombre);
}

.material-symbols-rounded.close {
    font-size: 36px;
}

.no_fav {
    font-size: 20px;
}

.lastFav {
    border-radius: 0 0 5px 5px;
}

.section__items_wrap::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #f0f0f0;
}

.lastFav::after {
    content: '';
    width: 0%;
    height: 0px;
}

.last_name {
    border-radius: 0 0 5px 5px;
}

.last_name::after {
    content: '';
    display: none;
}

.close {
    border-radius: 50%;
    padding: 3px;
    transition: all 0.1s ease;
}

.close:hover {
    cursor: pointer;
    background: rgb(27, 137, 206);
    color: var(--blanc);
}

/* NODATA -------------------------------- */
.no_data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    height: fit-content;
    padding: 25px var(--padding-left);
}

.no_data_img {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
}

.no_data_img img {
    height: 250px;
}

.no_data_p {
    text-align: center;
    color: #555555;
    font-size: 28px;
    font-weight: 500;
    user-select: none;
}

.no_data_back {
    border: 1px solid #bbbbbb;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 174px;
    border-radius: 20px;
    background: transparent;
    padding: 5px 15px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all .4s ease;
    outline: none;
    font-size: 18px;
}


.no_data_back .back_arrow {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .4s ease;
    color: var(--bleu);
    cursor: pointer;
}

.no_data_back:hover {
    border: 1px solid var(--bleu);
    background: #F9F9F9;
    width: 200px;
}

.no_data_back:active {
    background: #d6d6d6;
    box-shadow: 0px 0px 0px 4px rgba(27, 137, 206, 0.2);
}

.no_data_back:hover .back_arrow,
.no_data_back:active .back_arrow {
    transform: translate(50%, -50%);
    opacity: 1;
}

/* SUMMONER -------------------------- */
.summoner {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    width: 70vw;
}

.summoner_hero {
    display: flex;
    flex-direction: column;
}

.summoner_hero__p {
    color: #AAAAAA;
    font-size: 18px;
}

.summoner_hero__name {
    font-size: 48px;
}

.summoner_icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: var(--padding-left);
}

.summoner_icon__img {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bleu);
    width: 110px;
    height: 110px;
    position: relative;
}

.summoner_icon__level {
    border: 3px solid var(--bleu);
    background: #EEEEEE;
    position: absolute;
    bottom: 0;
    transform: translateY(40%);
    border-radius: 50px;
    padding: 0px 10px;
}

.summoner_icon__level_p {
    color: var(--bleu);
    font-size: 18px;
    user-select: none;
}

.summoner_icon__img,
.summoner_icon__img img {
    border-radius: 5px;
}

.summoner_icon__img img {
    height: 100px;
    width: 100px;
}

.summoner_icon_handler {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    width: 100%;
}

.summoner_icon_handler .star {
    font-size: 48px;
}

.material-symbols-rounded {
    cursor: default;
    user-select: none;
}

@keyframes star-active {
    0% {
        color: var(--bleu);
        transform: scale(1);
    }

    25% {
        color: #65c4ff;
        transform: scale(1.5);
    }

    100% {
        color: var(--bleu);
        transform: scale(1);
    }
}

.star-active {
    color: var(--bleu);
    animation: star-active .5s ease;
}

.mastery {
    margin-top: 10px;
    display: none;
}

.mastery--active {
    display: block;
}

.search__mastery {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    margin-top: 5px;
}

.search__mastery input {
    border: none;
    width: 100%;
    height: 100%;
    padding: 15px;
    font-size: 24px;
    color: var(--bleu);
    border-radius: 5px;
}

.search__mastery input::placeholder {
    color: #777777;
}

.listaus {
    margin-top: 15px;
    background: var(--blanc);
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    display: none;
    flex-direction: column;
    list-style: none;
    gap: 15px;
    padding: 15px;
}

.listaus__item {
    display: none;
}

.listaus__item_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.listaus__item__img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.listaus__item__img img {
    border-radius: 5px;
}

.listaus__item__name {
    font-size: 20px;
}

.champ_info__card {
    margin-top: 20px;
    background: transparent;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    font-size: 24px;
}

.champ_info__card_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.champ_info__card_img img,
.champ_info__card_rank img {
    height: 55px;
    width: 55px;
}

.champ_info__card__item {
    display: flex;
    background: var(--blanc);
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    padding: 15px;
    gap: 15px;
    width: 100%;
    height: 80px;
}

.champ_info__card__item__part1,
.champ_info__card__item__part2,
.champ_info__card__item__part3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: 24px;
}

.champ_info__card__item__part1 {
    gap: 15px;
}

.champ_info__card__item__part3 {
    width: 100%;
}

.separator {
    border: 1px solid #D9D9D9;
    height: 100%;
    width: 2px;
}



/* MEDIA QUERIES ------------------------------------------------------------------------------------------------ */
@media (max-width: 1024px) {
    :root {
        --background: #f9f9f9;
        --padding-left: 100px;
    }

    nav {
        padding: calc(var(--padding-left) / 2);
        gap: 30px;
    }

    .logo_wrap {
        height: 80px;
        min-width: 80px;
    }

    .lux {
        height: 70px;
        width: 70px;
    }

    .search {
        height: 60px;
    }

    .search__region {
        font-size: 28px;
        width: 20%;
    }

    .search__name input {
        font-size: 28px;
    }

    input::placeholder {
        color: #777777;
    }

    .search_icon {
        margin-right: 20px;
    }

    .search__name .search_icon {
        font-size: 36px;
    }

    section {
        height: fit-content;
        width: 100%;
    }

    .summoner {
        margin-top: 0;
        width: 100vw;
    }

    .champ_info__card__item__part1,
    .champ_info__card__item__part2,
    .champ_info__card__item__part3 {
        font-size: 36px;
    }

    .champ_info__card__item__part1 {
        gap: 25px;
    }

    .champ_info__card__item {
        gap: 25px;
        width: 100%;
        height: 80px;
    }
}

@media (max-width: 414px),
(max-width: 820px) {
    :root {
        --background: #f9f9f9;
        --padding-left: 40px;
    }

    nav {
        padding: 20px;
        gap: 20px;
    }

    .logo_wrap {
        height: 60px;
        min-width: 60px;
    }

    .lux {
        height: 50px;
        width: 50px;
    }

    .search {
        height: 40px;
    }

    .search__region {
        font-size: 16px;
        width: 30%;
    }

    .search__name input {
        font-size: 16px;
    }

    input::placeholder {
        color: #777777;
    }

    .search_icon {
        margin-right: 15px;
    }

    .search__name .search_icon {
        font-size: 24px;
    }

    .tooltip,
    .tooltip img {
        height: 43px;
    }

    .tooltip__text {
        font-size: 9px;
    }

    .tooltip .tooltip__text {
        padding: 10px;
        width: 300px;
        top: -35%;
        left: 100%;
        line-height: 0.9rem;
        transform: translateY(5px);
    }

    .tooltip .tooltip__text::after {
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
    }

    section {
        height: fit-content;
        width: 100%;
    }

    .fav_summoner {
        margin-top: 40px;
    }

    .section__title {
        height: 35px;
    }

    .section__title_p {
        font-size: 16px;
        padding-left: 10px;
    }

    .section__items_wrap {
        padding-left: 10px;
        padding-right: 20px;
        height: 40px;
    }

    .no_fav {
        font-size: 14px;
    }

    .section__items_names {
        font-size: 16px;
    }

    .material-symbols-rounded.close {
        font-size: 24px;
    }

    .no_data {
        gap: 5px;
        width: 100%;
        height: fit-content;
        padding: 15px var(--padding-left);
    }

    .no_data_img img {
        height: 150px;
    }

    .no_data_p {
        font-size: 16px;
        font-weight: 500;
        color: var(--sombre);
    }

    .no_data_back {
        border: 1px solid #bbbbbb;
        margin-top: 50px;
        width: 140px;
        border-radius: 20px;
        padding: 5px 15px;
        font-size: 13.333;
    }

    .no_data_back:hover {
        width: 165px;
    }

    .summoner {
        margin-top: 0;
        width: 100vw;
    }

    .summoner_hero__p {
        font-size: 12px;
    }

    .summoner_hero__name {
        font-size: 26px;
    }

    .summoner_icon__img {
        width: 56px;
        height: 56px;
    }

    .summoner_icon__img img {
        height: 50px;
        width: 50px;
    }

    .summoner_icon__level {
        border: 2px solid var(--bleu);
        transform: translateY(40%);
        padding: 0px 5px;
    }

    .summoner_icon__level_p {
        font-size: 10px;
    }

    .summoner_icon_handler .star {
        font-size: 36px;
    }

    .search__mastery {
        width: 100%;
        height: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        margin-top: 5px;
    }

    .search__mastery input {
        font-size: 16px;
    }

    .listaus {
        padding: 15px;
    }

    .champ_info__card {
        gap: 15px;
        font-size: 20px;
    }

    .champ_info__card_img img,
    .champ_info__card_rank img {
        height: 40px;
        width: 40px;
    }

    .champ_info__card__item {
        padding: 10px;
        gap: 15px;
        width: 100%;
        height: 60px;
    }

    .champ_info__card__item__part1,
    .champ_info__card__item__part2,
    .champ_info__card__item__part3 {
        font-size: 16px;
    }

}